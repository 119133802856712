import { parse, stringify } from 'query-string'
import Cookies from 'js-cookie'

export const GTM_ID =
  process.env.NEXT_PUBLIC_I18N_GTM_ID &&
  JSON.parse(process.env.NEXT_PUBLIC_I18N_GTM_ID)

export const CLARITYID =
  process.env.NEXT_PUBLIC_I18N_CLARITYID &&
  JSON.parse(process.env.NEXT_PUBLIC_I18N_CLARITYID)

export const RECAPTCHA_SECRET = process.env.NEXT_PUBLIC_GOOGLE_RECAPTCHA_SITEKEY

export const GTAG_ID =
  process.env.NEXT_PUBLIC_I18N_GTAG_ID &&
  JSON.parse(process.env.NEXT_PUBLIC_I18N_GTAG_ID)

export const GTMtagsToCollection = (tags) => {
  const collections = [
    {
      name: 'Charger',
      tags: [
        'foriphoneandmore',
        'carchargers',
        'forlaptops',
        'fortablets',
        'Oculus Quest 2',
        'megnetic',
        'stands',
        'pads',
        'wirelesscarchargers',
        'stations',
        'formultidevice',
      ],
    },
    {
      name: 'Connectivity',
      tags: [
        'foriphonelightning',
        'usbc',
        'cableother',
        'hubs',
        'adapters',
        'dockingstations',
      ],
    },
    {
      name: 'Battery',
      tags: [
        'under3phonecharges',
        '34phonecharges',
        'cableother',
        '4plusphonecharges',
        '12phonecharges',
        'wireless',
        'fusion',
      ],
    },
    {
      name: 'ACDC',
      tags: ['powerstrips', 'outletextenders', 'usboutlets'],
    },
    {
      name: 'PowerHouse',
      tags: [
        'portablepowerstations',
        'solarpanels',
        'solargenerators',
        'solix',
      ],
    },
    {
      name: 'hes',
      tags: ['Balkonkraftwerk'],
    },
    {
      name: 'solarbank',
      tags: ['solarbank'],
    },
    {
      name: 'PowerCooler',
      tags: ['Cooler'],
    },
    {
      name: 'solix_bundle',
      tags: ['solix_bundle'],
    },
  ]

  const target = collections
    .filter((item) => {
      return item.tags.some(
        (tag) => tag === tags || tags?.some((t) => t?.trim() === tag)
      )
    })
    ?.pop()
  return target ? target?.name : ''
}

export const getCollection = ({ nodes }) => {
  return (
    nodes &&
    nodes?.find((node) => {
      // 返回 node?.handle 的值不是中文
      return (
        node?.handle !== 'all' &&
        node?.handle !== 'all-product' &&
        node?.handle?.match(/^[a-zA-Z0-9_\-]*$/)
      )
    })
  )
}

export const tagsToCollection = (tags) => {
  const collections = [
    {
      name: 'Charger',
      handle: 'chargers',
      deHandle: 'wandladegerate',
      tags: [
        'foriphoneandmore',
        'carchargers',
        'forlaptops',
        'fortablets',
        'Oculus Quest 2',
      ],
    },
    {
      name: 'Power Stations',
      handle: 'power-stations',
      deHandle: 'ladestationen',
      tags: ['portablepowerstations', 'solarpanels', 'solargenerators'],
    },
    {
      name: 'Power Banks',
      handle: 'power-banks',
      deHandle: 'powerbanks',
      tags: [
        'under3phonecharges',
        '34phonecharges',
        '4plusphonecharges',
        '12phonecharges,wireless',
        'fusion',
      ],
    },
    {
      name: 'Hubs and Docks',
      handle: 'hubs-and-docks',
      deHandle: 'adapter-hubs',
      tags: ['hubs', 'adapters', 'dockingstations'],
    },
    {
      name: 'AC Power',
      handle: 'ac-power',
      deHandle: 'ac-power',
      tags: ['powerstrips', 'outletextenders', 'usboutlets'],
    },
    {
      name: 'Wireless',
      handle: 'wireless',
      deHandle: 'kabelloses-zubehor',
      tags: ['megnetic', 'stands', 'pads', 'wirelesscarchargers', 'stations'],
    },
    {
      name: 'Cables',
      handle: 'cables',
      deHandle: 'kabel',
      tags: ['foriphonelightning', 'usbc', 'cableother'],
    },
  ]

  const target = collections
    .filter((item) => {
      return item.tags.some(
        (tag) => tag === tags || tags?.some((t) => t === tag)
      )
    })
    ?.pop()
  return target
}

// GTM - dataLayer
export const pageGTMEvent = (obj) => {
  if (typeof window === 'undefined') {
    return ''
  }
  window.dataLayer = window?.dataLayer || []
  if (!Array.isArray(window.dataLayer)) {
    return ''
  }
  try {
    window?.dataLayer?.push({ event_parameters: null })
    window?.dataLayer?.push(obj || {})
  } catch (error) {
    console.log('window.dataLayer error', window.dataLayer)
    console.log('pageGTMEvent error', error)
  }
}

// Twttr
export const pageTwttr = (obj) => {
  try {
    if (!twttr?.conversion?.trackPid) return false
    twttr?.conversion?.trackPid(obj.event, { ...obj })
  } catch (error) {
    console.log('twttr error')
  }
}

// google recaptcha
export const reExecute = async ({ action }) => {
  if (!window?.grecaptcha?.execute) return false
  else {
    return await window?.grecaptcha?.execute(`${RECAPTCHA_SECRET}`, {
      action,
    })
  }
}

// 获取亚马逊链接参数，客户端才能使用此方法
export const getAmazonLinkParams = (asin, ad_campaign, locale) => {
  if (typeof window === 'undefined') {
    return ''
  }
  const massMapping = {
    us: 'maas_adg_api_8014460300101_static_6_26',
    'eu-de': 'maas_adg_api_3314480650102_macro_2_26',
    uk: 'maas_adg_api_588563440394542563_macro_1_26',
  }
  const q = (key) => parse(window.location.search)[key] || 'unknown'
  // console.log('q', parse(window.location.search))
  return stringify({
    maas: massMapping[locale],
    ref_: 'aa_maas',
    aa_campaignid: (ad_campaign || '') + (asin || ''),
    aa_adgroupid: q('utm_term'),
    aa_creativeid: `${q('utm_source')}_${q('utm_medium')}_${q('utm_campaign')}`,
  })
}

export const appendAMZPixel = (src) => {
  const img = document.createElement('img')
  img.src = src
  document.getElementById('amazon_pixel')?.appendChild(img)
}

export const admitadRetag = ({ code, level }) => {
  window._retag = window?._retag || []
  window._retag.push({ code, level })
  var id = 'admitad-retag'
  if (document.getElementById(id)) return
  var s = document.createElement('script')
  s.async = true
  s.id = id
  var r = new Date().getDate()
  s.src =
    (document.location.protocol == 'https:' ? 'https:' : 'http:') +
    '//cdn.lenmit.com/static/js/retag.js?r=' +
    r
  var a = document.getElementsByTagName('script')[0]
  a.parentNode.insertBefore(s, a)
}

export const getPersonaCookie = () => {
  // 活动 cookie
  return Cookies.get('persona')
}

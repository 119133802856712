import { useMemo } from 'react'
import { useCommerce } from '..'

export const languageCode = (locale: string) =>
  ({
    us: 'US',
    uk: 'GB',
    ca: 'CA',
    fr: 'FR',
    au: 'AU',
    my: 'MY',
    vn: 'VN',
    it: 'IT',
    'eu-de': 'DE',
    'eu-en': 'DE',
  }[locale] || 'US')

export const languageTerritory = (locale: string) =>
  // https://unicode-org.github.io/cldr-staging/charts/37/supplemental/language_territory_information.html
  ({
    us: 'en-US',
    uk: 'en-GB',
    ca: 'en-CA',
    fr: 'fr-FR',
    au: 'en-AU',
    my: 'my',
    vn: 'vn',
    'eu-de': 'de-DE',
    'eu-en': 'en-DE',
  }[locale] || 'en-US')

export const languageCurrencyCode = (locale: string) =>
  // https://www.iban.com/currency-codes
  ({
    us: 'USD',
    uk: 'GBP',
    ca: 'CAD',
    fr: 'EUR',
    au: 'AUD',
    my: 'MYR',
    vn: 'VND',
    'eu-de': 'EUR',
    'eu-en': 'EUR',
  }[locale] || 'us')

export const languageCountry = (locale: string) =>
  ({
    us: 'United States',
    uk: 'United Kingdom',
    ca: 'Canada',
    my: 'Malaysia',
    vn: 'Vietnam',
    'eu-de': 'Germany',
    'eu-en': 'Europe',
  }[locale] || 'en-US')

export function formatPrice({
  amount,
  currencyCode,
  locale,
  maximumFractionDigits,
  minimumFractionDigits,
}: {
  amount: number
  currencyCode: string
  locale: string
  maximumFractionDigits?: number
  minimumFractionDigits?: number
}) {
  if (!currencyCode) return amount
  const formatCurrency = new Intl.NumberFormat(languageTerritory(locale), {
    style: 'currency',
    currency: currencyCode,
    minimumFractionDigits:
      maximumFractionDigits === 0 ? 0 : minimumFractionDigits,
    maximumFractionDigits,
  })

  const result = formatCurrency.format(amount)
  return locale === 'eu-de' || locale === 'eu-en'
    ? result?.toString()?.replace(/[\s]/g, '')
    : result
}

export function formatVariantPrice({
  amount,
  baseAmount,
  currencyCode,
  locale,
}: {
  baseAmount: number
  amount: number
  currencyCode: string
  locale: string
}) {
  const hasDiscount = baseAmount > amount
  const formatDiscount = new Intl.NumberFormat(languageTerritory(locale), {
    style: 'percent',
  })
  const discount = hasDiscount
    ? formatDiscount.format((baseAmount - amount) / baseAmount)
    : null

  const price = formatPrice({ amount, currencyCode, locale })
  const basePrice = hasDiscount
    ? formatPrice({ amount: baseAmount, currencyCode, locale })
    : null

  return { price, basePrice, discount }
}

export default function usePrice(
  data?: {
    amount: number
    baseAmount?: number
    currencyCode: string
    soldOutDescription: string
  } | null
) {
  const { amount, baseAmount, currencyCode, soldOutDescription } = data ?? {}
  const { locale } = useCommerce()
  const value = useMemo(() => {
    if (typeof amount !== 'number' || !currencyCode) return ''
    if (soldOutDescription && amount >= 9999999.99) {
      return soldOutDescription
    }
    return baseAmount
      ? formatVariantPrice({ amount, baseAmount, currencyCode, locale })
      : formatPrice({ amount, currencyCode, locale })
  }, [amount, baseAmount, currencyCode, locale, soldOutDescription])
  const free = amount && amount <= 0

  return typeof value === 'string' || typeof value === 'number'
    ? { price: value, free }
    : { ...value, free }
}

import { reExecute } from '@lib/utils/thirdparty'
import { COMMERCE_FEATURES, I18N_THEME_DOMAIN } from '@shopify/const'
import { formatPrice } from '@shopify/product/use-price'
import CryptoJS from 'crypto-js'
import Cookies from 'js-cookie'

export const insertSeparator = (arr, separator) => {
  // 在一个数组中间隔插入另外一个值
  return [].concat(
    ...arr.map((item, index) =>
      index < arr.length - 1 ? [item, separator] : [item]
    )
  )
}

export const commerceEnabled = (feature, locale) => {
  const LOCAL = COMMERCE_FEATURES?.[locale]?.[feature]
  if (typeof LOCAL === 'boolean') return LOCAL
  return process.env[`COMMERCE_${feature.toUpperCase()}_ENABLED`]
}

export const digestMessage = async (message) => {
  if (!window || !crypto) return ''
  const msgUint8 = new TextEncoder().encode(message) // encode as (utf-8) Uint8Array
  const hashBuffer = await crypto.subtle.digest('SHA-1', msgUint8) // hash the message
  const hashArray = Array.from(new Uint8Array(hashBuffer)) // convert buffer to byte array
  const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('') // convert bytes to hex string
  return hashHex
}

export const appendRefParameter = ({ url, refValue }) => {
  if (!url) return url
  // 检查链接是否已包含查询字符串
  const hasQueryString = url?.includes('?')

  // 构建 ref 参数
  const refParameter = `ref=${encodeURIComponent(refValue)}`

  // 检查链接是否已包含哈希值
  const hasHash = url?.includes('#')

  // 判断链接的拼接方式
  if (hasQueryString) {
    // 链接已包含查询字符串，使用 "&" 连接 ref 参数
    url += `&${refParameter}`
  } else {
    // 链接未包含查询字符串，使用 "?" 连接 ref 参数
    url += `?${refParameter}`
  }

  // 如果链接已包含哈希值，则保留哈希值
  if (hasHash) {
    const [baseUrl, hash] = url.split('#')
    return `${baseUrl}&${refParameter}#${hash}`
  }

  return url
}

// 生成or获取 游客的id
export const generateVisitorId = () => {
  // 检查本地存储中是否已有唯一标识符
  let visitorId = Cookies.get('visitorId')

  // 如果本地存储中没有唯一标识符，则创建一个新的唯一标识符
  if (!visitorId) {
    visitorId =
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15)

    const hostUrl = window.location.host.split('.')
    const domain =
      hostUrl.length > 2
        ? `.${hostUrl[1]}.${hostUrl[2]}`
        : window.location.hostname
    Cookies.set('visitorId', visitorId, {
      domain,
      expires: 90,
    })
  }

  return visitorId
}

export const deleteMFkey = (obj) => {
  return Object.fromEntries(
    Object.entries(obj).filter(([key, value]) => {
      return !key.includes('MF_')
    })
  )
}

export const cleatShopKey = (shop) => {
  delete shop.privacyPolicy
  delete shop.refundPolicy
  delete shop.shippingPolicy
  delete shop.termsOfService
  return shop
}

export const getURLParameter = (name, url) => {
  if (!url) url = window.location.href
  name = name.replace(/[\[\]]/g, '\\$&')
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)')
  var results = regex.exec(url)
  if (!results) return null
  if (!results[2]) return ''
  return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

export function atobID(id) {
  if (!id) {
    return null
  }
  if (typeof id === 'string' && id.includes('/')) {
    return id.split('/').pop().split('?').shift()
  }
  return id
  // const value = id.toString()
  // if (/[a-zA-Z]/i.test(value)) {
  //   let gid;
  //   if (isBrowser()) {
  //     gid = window.atob(value).split('/')
  //   } else {
  //     gid = Buffer.from(value, 'base64').toString('ascii').split('/')
  //   }
  // }
  // return value
}

export function btoaID(id, type = 'ProductVariant') {
  return `gid://shopify/${type}/${id}` || id
}

export function getGaClientId() {
  return new Promise((resolve) =>
    setTimeout(() => {
      if (!isBrowser) return resolve(false)
      if (typeof ga === 'undefined') return resolve(false)
      try {
        const trackers = ga?.getAll()?.[0]?.get('clientId')
        return resolve(trackers)
      } catch (error) {
        return resolve('')
      }
    }, 1000)
  )
}

export function isBrowser() {
  return typeof window !== 'undefined'
}

export function getThemeUrl(link, locale, buried = '') {
  if (link?.includes('http')) return link + buried
  return `https://${I18N_THEME_DOMAIN[locale]}${link}${buried}`
}

export function md5Func(r) {
  function n(r, n) {
    var t, o, e, u, f
    return (
      (e = 2147483648 & r),
      (u = 2147483648 & n),
      (f = (1073741823 & r) + (1073741823 & n)),
      (t = 1073741824 & r) & (o = 1073741824 & n)
        ? 2147483648 ^ f ^ e ^ u
        : t | o
        ? 1073741824 & f
          ? 3221225472 ^ f ^ e ^ u
          : 1073741824 ^ f ^ e ^ u
        : f ^ e ^ u
    )
  }
  function t(r, t, o, e, u, f, a) {
    return (
      (r = n(r, n(n((t & o) | (~t & e), u), a))),
      n((r << f) | (r >>> (32 - f)), t)
    )
  }
  function o(r, t, o, e, u, f, a) {
    return (
      (r = n(r, n(n((t & e) | (o & ~e), u), a))),
      n((r << f) | (r >>> (32 - f)), t)
    )
  }
  function e(r, t, o, e, u, f, a) {
    return (r = n(r, n(n(t ^ o ^ e, u), a))), n((r << f) | (r >>> (32 - f)), t)
  }
  function u(r, t, o, e, u, f, a) {
    return (
      (r = n(r, n(n(o ^ (t | ~e), u), a))), n((r << f) | (r >>> (32 - f)), t)
    )
  }
  function f(r) {
    var n,
      t = '',
      o = ''
    for (n = 0; 3 >= n; n++)
      t += (o = '0' + (o = (r >>> (8 * n)) & 255).toString(16)).substr(
        o.length - 2,
        2
      )
    return t
  }
  var a, i, C, c, g, h, d, v, S
  for (
    a = (function (r) {
      for (
        var n,
          t = r.length,
          o = 16 * (((n = t + 8) - (n % 64)) / 64 + 1),
          e = Array(o - 1),
          u = 0,
          f = 0;
        f < t;

      )
        (u = (f % 4) * 8),
          (e[(n = (f - (f % 4)) / 4)] |= r.charCodeAt(f) << u),
          f++
      return (
        (e[(n = (f - (f % 4)) / 4)] |= 128 << ((f % 4) * 8)),
        (e[o - 2] = t << 3),
        (e[o - 1] = t >>> 29),
        e
      )
    })(
      (r = (function (r) {
        r = r.replace(/\r\n/g, '\n')
        for (var n = '', t = 0; t < r.length; t++) {
          var o = r.charCodeAt(t)
          128 > o
            ? (n += String.fromCharCode(o))
            : (127 < o && 2048 > o
                ? (n += String.fromCharCode((o >> 6) | 192))
                : ((n += String.fromCharCode((o >> 12) | 224)),
                  (n += String.fromCharCode(((o >> 6) & 63) | 128))),
              (n += String.fromCharCode((63 & o) | 128)))
        }
        return n
      })(r))
    ),
      h = 1732584193,
      d = 4023233417,
      v = 2562383102,
      S = 271733878,
      r = 0;
    r < a.length;
    r += 16
  )
    (i = h),
      (C = d),
      (c = v),
      (g = S),
      (h = t(h, d, v, S, a[r + 0], 7, 3614090360)),
      (S = t(S, h, d, v, a[r + 1], 12, 3905402710)),
      (v = t(v, S, h, d, a[r + 2], 17, 606105819)),
      (d = t(d, v, S, h, a[r + 3], 22, 3250441966)),
      (h = t(h, d, v, S, a[r + 4], 7, 4118548399)),
      (S = t(S, h, d, v, a[r + 5], 12, 1200080426)),
      (v = t(v, S, h, d, a[r + 6], 17, 2821735955)),
      (d = t(d, v, S, h, a[r + 7], 22, 4249261313)),
      (h = t(h, d, v, S, a[r + 8], 7, 1770035416)),
      (S = t(S, h, d, v, a[r + 9], 12, 2336552879)),
      (v = t(v, S, h, d, a[r + 10], 17, 4294925233)),
      (d = t(d, v, S, h, a[r + 11], 22, 2304563134)),
      (h = t(h, d, v, S, a[r + 12], 7, 1804603682)),
      (S = t(S, h, d, v, a[r + 13], 12, 4254626195)),
      (v = t(v, S, h, d, a[r + 14], 17, 2792965006)),
      (h = o(
        h,
        (d = t(d, v, S, h, a[r + 15], 22, 1236535329)),
        v,
        S,
        a[r + 1],
        5,
        4129170786
      )),
      (S = o(S, h, d, v, a[r + 6], 9, 3225465664)),
      (v = o(v, S, h, d, a[r + 11], 14, 643717713)),
      (d = o(d, v, S, h, a[r + 0], 20, 3921069994)),
      (h = o(h, d, v, S, a[r + 5], 5, 3593408605)),
      (S = o(S, h, d, v, a[r + 10], 9, 38016083)),
      (v = o(v, S, h, d, a[r + 15], 14, 3634488961)),
      (d = o(d, v, S, h, a[r + 4], 20, 3889429448)),
      (h = o(h, d, v, S, a[r + 9], 5, 568446438)),
      (S = o(S, h, d, v, a[r + 14], 9, 3275163606)),
      (v = o(v, S, h, d, a[r + 3], 14, 4107603335)),
      (d = o(d, v, S, h, a[r + 8], 20, 1163531501)),
      (h = o(h, d, v, S, a[r + 13], 5, 2850285829)),
      (S = o(S, h, d, v, a[r + 2], 9, 4243563512)),
      (v = o(v, S, h, d, a[r + 7], 14, 1735328473)),
      (h = e(
        h,
        (d = o(d, v, S, h, a[r + 12], 20, 2368359562)),
        v,
        S,
        a[r + 5],
        4,
        4294588738
      )),
      (S = e(S, h, d, v, a[r + 8], 11, 2272392833)),
      (v = e(v, S, h, d, a[r + 11], 16, 1839030562)),
      (d = e(d, v, S, h, a[r + 14], 23, 4259657740)),
      (h = e(h, d, v, S, a[r + 1], 4, 2763975236)),
      (S = e(S, h, d, v, a[r + 4], 11, 1272893353)),
      (v = e(v, S, h, d, a[r + 7], 16, 4139469664)),
      (d = e(d, v, S, h, a[r + 10], 23, 3200236656)),
      (h = e(h, d, v, S, a[r + 13], 4, 681279174)),
      (S = e(S, h, d, v, a[r + 0], 11, 3936430074)),
      (v = e(v, S, h, d, a[r + 3], 16, 3572445317)),
      (d = e(d, v, S, h, a[r + 6], 23, 76029189)),
      (h = e(h, d, v, S, a[r + 9], 4, 3654602809)),
      (S = e(S, h, d, v, a[r + 12], 11, 3873151461)),
      (v = e(v, S, h, d, a[r + 15], 16, 530742520)),
      (h = u(
        h,
        (d = e(d, v, S, h, a[r + 2], 23, 3299628645)),
        v,
        S,
        a[r + 0],
        6,
        4096336452
      )),
      (S = u(S, h, d, v, a[r + 7], 10, 1126891415)),
      (v = u(v, S, h, d, a[r + 14], 15, 2878612391)),
      (d = u(d, v, S, h, a[r + 5], 21, 4237533241)),
      (h = u(h, d, v, S, a[r + 12], 6, 1700485571)),
      (S = u(S, h, d, v, a[r + 3], 10, 2399980690)),
      (v = u(v, S, h, d, a[r + 10], 15, 4293915773)),
      (d = u(d, v, S, h, a[r + 1], 21, 2240044497)),
      (h = u(h, d, v, S, a[r + 8], 6, 1873313359)),
      (S = u(S, h, d, v, a[r + 15], 10, 4264355552)),
      (v = u(v, S, h, d, a[r + 6], 15, 2734768916)),
      (d = u(d, v, S, h, a[r + 13], 21, 1309151649)),
      (h = u(h, d, v, S, a[r + 4], 6, 4149444226)),
      (S = u(S, h, d, v, a[r + 11], 10, 3174756917)),
      (v = u(v, S, h, d, a[r + 2], 15, 718787259)),
      (d = u(d, v, S, h, a[r + 9], 21, 3951481745)),
      (h = n(h, i)),
      (d = n(d, C)),
      (v = n(v, c)),
      (S = n(S, g))
  return (f(h) + f(d) + f(v) + f(S)).toLowerCase()
}

// Function to perform SHA-256 hashing
export function sha256(str) {
  return CryptoJS.SHA256(str).toString()
}

export function encHex() {
  return CryptoJS.enc.Hex
}

export function isMobile() {
  let check = false
  ;(function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true
  })(navigator.userAgent || navigator.vendor || window.opera)
  return check
}

export const serializeObject = (obj) => {
  return Object.entries(obj)
    .map(([key, value]) => {
      return key + '=' + encodeURIComponent(value)
    })
    .join('&')
}

export function parseTemplate(template, map, fallback) {
  /**
   * 解析字符串模板，用于将变量配置在外部 CMS 例如 metafields 时
   */
  const get = (path, obj, fb = `$\{${path}}`) =>
    path?.split('.').reduce((res, key) => res[key] || fb, obj)

  return template.replace(/\$\{.+?}/g, (match) => {
    const path = match.substr(2, match.length - 3).trim()
    return get(path, map, fallback)
  })
}

export const getThemeLocale = (locale) => {
  if (locale === 'eu-de') return 'de'
  if (locale === 'eu-en') return 'eu'
  if (locale === 'au') return 'au-shop'
  return locale
}

export const refreshYotpoWidgets = () => {
  try {
    yotpo.refreshWidgets()
    let initYotpo = false
    for (let i = 0, len = window.yotpo.widgets.length; i < len; i++) {
      if (
        !window.yotpo.widgets[i].settings.main_widget_pid &&
        window.yotpo.widgets[i].analyticsCategory === 'star_rating'
      ) {
        initYotpo = true
        window.yotpo.widgets[i].settings.skip_average_score = false
        window.yotpo.widgets[i].settings.main_widget_pid =
          window.yotpo.widgets[i].settings.widget_product_id
      }
    }
    if (initYotpo) {
      setTimeout(() => {
        window.yotpo.initialized = false
        window.yotpo.clean()
        window.yotpo.initWidgets()
      }, 300)
    }
  } catch (errors) {
    console.log('refreshYotpoWidgets errors', errors)
  }
}

export const refreshStarRating = () => {
  if (window?.jdgm) {
    typeof window.jdgm?.customizeBadges === 'function' &&
      window.jdgm?.customizeBadges()
  }
  if (window?.yotpo) {
    refreshYotpoWidgets()
  }
}

export const refreshReivews = () => {
  if (window?.jdgm) {
    const dom = document
    jdgm?.loadScript?.requestedUrls.map((item, index) => {
      const a = dom.createElement('script')
      a.className = 'jdgm-script' + index
      a.type = 'text/javascript'
      a.src = item
      a.async = !0
      dom.body.appendChild(a)
    })

    const reviewCount = dom
      .querySelector('.jdgm-prev-badge')
      ?.getAttribute('data-number-of-reviews')
    if (dom.querySelector('.jdgm-rev-widg__title')) {
      dom.querySelector('.jdgm-rev-widg__title').textContent =
        jdgmSettings?.widget_title
    }
    if (dom.querySelector('.jdgm-rev-widg__summary-text')) {
      dom.querySelector('.jdgm-rev-widg__summary-text').textContent =
        reviewCount === '0'
          ? jdgmSettings?.widget_no_review_text
          : jdgmSettings?.widget_summary_text?.replace(
              '{{ number_of_reviews }}',
              reviewCount
            )
    }
  }
}

// 获取产品的 tag
export const getProductTag = ({ product, variant, custom, tagIndex = 0 }) => {
  if (custom) return custom
  if (variant?.variantTags) return variant?.variantTags?.[tagIndex] || ''
  if (variant?.tags) return variant?.tags?.[tagIndex] || ''
  return product?.tags?.[tagIndex] || ''
}

//去掉所有的html标记
export const delHtmlTag = (str) => (str ? str.replace(/<[^>]+>/g, '') : '')

// 根据 array of object 中某 attribute 去重
export const getUniqueListBy = (arr, key) => {
  return [...new Map(arr.map((item) => [item[key], item])).values()]
}

export const getRandomValueFromArray = (arr) => {
  const randomIndex = Math.floor(Math.random() * arr.length)
  return arr[randomIndex]
}

export const addQueryParam = ({ url, key, value, replaceHistory }) => {
  const urlObj = new URL(url)
  const searchParams = new URLSearchParams(urlObj.search)

  if (searchParams.has(key)) {
    searchParams.set(key, value)
  } else {
    searchParams.append(key, value)
  }

  urlObj.search = searchParams.toString()
  const newUrl = urlObj.toString()

  if (replaceHistory) {
    window.history.replaceState({}, '', newUrl)
  }
  return newUrl
}

/**
 * 谷歌验证埋点上报
 * @param {*} action
 * @returns
 */
export function recaptchaCollect({ action }) {
  const ankerSiteId = '6'
  return fetch(
    `https://matomo.anker-in.com/matomo.php?idsite=${ankerSiteId}&rec=1&action_name=${action}&e_c=captcha&e_a=${
      process.env.NEXT_PUBLIC_ENV === 'production'
        ? 'generate'
        : 'beta_generate'
    }&e_n=google`
  )
}

/**
 * 谷歌验证需要的请求头
 * @param {*} action
 * @param {*} recaptchaHeaderKey ：recaptcha
 * @returns
 */
export async function getRecaptchaHeaders(
  action = 'activity',
  { recaptchaHeaderKey = 'X-Recaptcha-Token' } = {}
) {
  const recaptchaToken = await reExecute({ action })
  recaptchaCollect({ action })
  return {
    [recaptchaHeaderKey]: recaptchaToken,
    'X-Recaptcha-Sitekey': process.env.NEXT_PUBLIC_GOOGLE_RECAPTCHA_SITEKEY,
  }
}

export const getPassportSign = (email, sign = 'email') => {
  const signKey = process.env.NEXT_PUBLIC_SIGN_KEY
  return CryptoJS.SHA256(`${signKey + sign}=${email}${signKey}`).toString()
}

export const getParameUrlCombineoptions = ({
  url,
  combineoptions_order,
  combineoptions,
}) => {
  const url_prest = {}
  combineoptions_order?.forEach((optionKey) => {
    const url_key = optionKey?.split('_')?.pop()
    const s = getURLParameter(`s_${url_key}`, url)
    const o = getURLParameter(`o_${url_key}`, url)
    const o_p = getURLParameter(`o_${url_key}_p`, url)
    const o_v = getURLParameter(`o_${url_key}_v`, url)

    const option = combineoptions?.[optionKey]
    const opt = option?.options?.[Number(o) - 1]
    if (s) {
      const s_opt = option?.options?.forEach((item) => {
        if (item?.variantSelect) {
          if (item?.variantSelect?.options?.some((v) => v === s)) {
            url_prest[optionKey] = `${item?.related_handle},${s}`
          }
        } else if (item?.related_handlesku) {
          const [handle, sku] = item?.related_handlesku?.split(',')
          if (sku === s) {
            url_prest[optionKey] = item?.related_handlesku
          }
        }
      })
      if (s_opt) url_prest[optionKey] = s_opt?.related_handlesku
    } else if (!o) {
      url_prest[optionKey] = ''
    } else if (opt && o_p) {
      url_prest[optionKey] = opt?.related_handlesku
      url_prest[`${optionKey}_partSelect`] = {
        value: o_p,
        related: opt?.related_handlesku,
        part_handlesku:
          opt?.partSelect?.related_handlesku || opt?.partSelect?.type,
      }
    } else if (opt && o_v) {
      const opt_v = opt?.variantSelect?.options?.[Number(o_v) - 1]

      url_prest[optionKey] = `${opt?.related_handle},${opt_v}`
    } else if (opt) {
      url_prest[optionKey] = opt?.related_handlesku
    }
  })
  if (
    Object.keys(url_prest).some(
      (key) => key !== 'topRecommended' && !!url_prest[key]
    )
  ) {
    url_prest.topRecommended = ''
  }
  Object.values(url_prest)?.some((v) => !!v) &&
    Object.keys(url_prest).forEach((key) => {
      if (!url_prest?.[key]) {
        const options = combineoptions?.[key]?.options || []
        options?.forEach((item) => {
          if (item?.requisite) {
            url_prest[key] = item?.requisite
          }
        })
      }
    })
  return url_prest
}

const getOptionLabel = ({
  key,
  products,
  combineOption,
  combineoptions,
  shotName,
}) => {
  let handlesku = ''
  // 判断是对象
  if (typeof combineOption?.[key] === 'object') {
    handlesku =
      Number(combineOption?.[key]?.value) > 0
        ? combineOption?.[key]?.part_handlesku
        : ''
  } else {
    handlesku = combineOption?.[key] || ''
  }

  let cur_name = ''

  if (handlesku) {
    let option_label = ''
    if (shotName) {
      const [handle, sku] = handlesku?.split(',')
      const product = products?.find((v) => v?.handle === handle)
      const variant = product?.variants?.find((v) => v?.sku === sku)
      const infos = variant?.metafields?.infos
      cur_name = infos?.combine_title || infos?.short_title
      if (cur_name) return cur_name
    }

    if (key?.includes('_partSelect')) {
      const m_key = key?.split('_partSelect')?.shift()
      option_label = combineoptions?.[m_key]?.options?.find(
        (item) =>
          item?.partSelect?.related_handlesku === handlesku &&
          !item?.partSelect?.type
      )?.partSelect?.label
    } else {
      option_label = combineoptions?.[key]?.options?.find(
        (item) => item?.related_handlesku === handlesku
      )?.label
    }

    if (option_label) return option_label
    const [handle, sku] = handlesku?.split(',')
    const product = products?.find((v) => v?.handle === handle)
    const variant = product?.variants?.find((v) => v?.sku === sku)
    return key?.includes('_requisite')
      ? product?.name
      : variant?.name || product?.name
  }
  return ''
}

export const getCombineName = ({
  products,
  combineOption,
  combineoptions,
  combineoptions_commoninfo,
  contextTradeGift,
}) => {
  if (combineoptions_commoninfo?.name_order) {
    const copy_combineOption = { ...combineOption }
    const result = combineoptions_commoninfo?.name_order?.reduce((pre, cur) => {
      const current = combineoptions?.[cur]?.options?.find(
        (item) => item?.related_handlesku === copy_combineOption?.[cur]
      )

      if (current?.special) {
        const hit = Object.entries(current?.special?.select)?.every(([k, v]) =>
          v?.includes(copy_combineOption[k])
        )
        if (hit) {
          copy_combineOption[cur] = current?.special?.handlesku
        }
      }

      const cur_name = getOptionLabel({
        key: cur,
        products,
        combineOption: copy_combineOption,
        combineoptions,
        shotName: true,
      })
      if (cur_name) {
        return `${pre} ${pre ? '+' : ''} ${cur_name}`
      }
      return pre
    }, '')

    if (contextTradeGift?.length > 0) {
      return contextTradeGift.reduce(
        (pre, cur) =>
          `${pre} + ${
            cur?.variant?.metafields?.infos?.combine_title || cur?.variant?.name
          }`,
        result
      )
    }
    return result
  }
}

export const getCombineVariant = ({
  locale,
  products,
  relatedCoupons,
  combineOption,
  combineoptions,
  combineoptions_order,
  combineoptions_commoninfo,
  memberDiscountData,
  customer,
  contextTradeGift,
}) => {
  if (!combineoptions_order?.length > 0) return null
  const getCoupons = (sku, handle) => {
    return relatedCoupons?.[sku]?.find((coupon) => coupon?.handle === handle)
  }

  const sku_to_bundle = combineoptions_commoninfo?.sku_to_bundle || []

  const copy_combineOption = { ...combineOption }

  const copy_order = [...combineoptions_order]

  const has_bundle = Object.entries(sku_to_bundle)
    ?.sort(
      ([a_bundlekey, a_bundle], [b_bundlekey, b_bundle]) =>
        b_bundle?.length - a_bundle?.length
    )
    ?.find(([bundlekey, bundle]) => {
      const deletedkey = []
      const result = bundle?.every((handlesku) =>
        Object.entries(combineOption)?.some(([key, option]) => {
          let mix = option
          const [h, s, q] = handlesku?.split(',')
          const [b_h, b_s] = bundlekey?.split(',')
          const product = products?.find((v) => v?.handle === b_h)
          const variant = product?.variants?.find((v) => v?.sku === b_s)
          if (!variant) return false
          if (q) {
            const quantity = combineOption?.[`${key}_partSelect`]?.value
            mix = `${option},${quantity}`
          }
          if (mix === handlesku) {
            deletedkey.push(key)

            return true
          }
        })
      )
      if (result) {
        deletedkey?.forEach((key) =>
          Reflect.deleteProperty(copy_combineOption, key)
        )
      }
      return result
    })

  if (has_bundle?.length > 0) {
    copy_order?.unshift('bundle_option')
    copy_combineOption.bundle_option = has_bundle[0]
  }

  const setObj = ({
    label,
    key,
    quantity = 1,
    coupon,
    product,
    variant,
    usePname,
    notpriced,
    script_discount,
  }) => {
    let discountValue = customer
      ? memberDiscountData?.products?.find(
          ([p]) => p?.handle === product?.handle && p?.sku === variant?.sku
        )?.[0]?.value
      : ''

    const price = formatPrice({
      amount: variant?.price * Number(quantity),
      currencyCode: product?.price?.currencyCode,
      locale,
      maximumFractionDigits: 2,
    })

    const couponTitle = notpriced || discountValue ? '' : coupon?.title

    const coupon_variant_price4wscode = notpriced
      ? 0.000001
      : discountValue || coupon?.variant_price4wscode

    return {
      label: usePname ? product?.name : label || variant?.name || product?.name,
      price,
      option_key: key,
      quantity,
      script_discount,
      coupon: {
        title: couponTitle,
        variant_price4wscode: coupon_variant_price4wscode,
      },
      product: {
        id: product?.id,
        name: product?.name,
        handle: product?.handle,
        availableForSale: product?.availableForSale,
      },
      variant: {
        tags: variant.tags,
        id: variant?.id,
        sku: variant?.sku,
        name: variant?.name,
        listPrice: variant?.listPrice,
        price: variant?.price,
        availableForSale: variant?.availableForSale,
        metafields: variant?.metafields,
      },
    }
  }
  if (copy_order) {
    const combination_v = copy_order?.reduce((pre, key) => {
      const current = combineoptions?.[key]?.options?.find(
        (item) => item?.related_handlesku === combineOption?.[key]
      )

      if (current?.special) {
        const hit = Object.entries(current?.special?.select)?.every(([k, v]) =>
          v?.includes(combineOption[k])
        )
        if (hit) {
          copy_combineOption[key] = current?.special?.handlesku
        }
      }

      const label = getOptionLabel({
        key,
        products,
        combineOption: copy_combineOption,
        combineoptions,
      })

      if (copy_combineOption?.[key]) {
        const [handle, sku] = copy_combineOption?.[key]?.split(',')
        const product = products?.find((v) => v?.handle === handle)
        const variant = product?.variants?.find((v) => v?.sku === sku)

        const part_quantity = combineOption?.[`${key}_partSelect`]

        let quantity = 0
        if (part_quantity?.part_handlesku === 'quantity') {
          quantity = Number(part_quantity?.value)
        } else {
          quantity =
            Object?.entries(combineoptions?.[key]?.quantity || {})?.find(
              ([key, value]) => {
                return Object?.entries(value)?.some(([k, v]) => {
                  return v?.includes(copy_combineOption?.[k])
                })
              }
            )?.[0] || 1
        }

        if (Number(quantity) === 0) return pre

        let coupon = getCoupons(variant?.sku, product?.handle)
        if (variant) {
          let notpriced = false
          if (current?.not_priced && has_bundle?.length > 0) {
            notpriced = current?.not_priced?.includes(
              copy_combineOption.bundle_option
            )
          }
          let script_discount = null
          if (combineoptions_commoninfo?.script_discount?.length) {
            script_discount = combineoptions_commoninfo?.script_discount?.find(
              (sd) =>
                sd?.discount_products === `${product?.handle},${variant?.sku}`
            )
          }
          const obj = setObj({
            label,
            key,
            quantity: Number(quantity),
            coupon,
            product,
            variant,
            notpriced,
            script_discount,
          })
          if (notpriced) console.log('variant', obj)
          pre?.push(obj)
        }
      }

      const requisite = copy_combineOption?.[`${key}_requisite`]
      if (requisite) {
        const [handle, sku] = requisite?.split(',')
        const product = products?.find((v) => v?.handle === handle)
        const variant = product?.variants?.find((v) => v?.sku === sku)

        const coupon = getCoupons(variant?.sku, product?.handle)

        if (variant) {
          let script_discount = null
          if (combineoptions_commoninfo?.script_discount?.length) {
            script_discount = combineoptions_commoninfo?.script_discount?.find(
              (sd) =>
                sd?.discount_products === `${product?.handle},${variant?.sku}`
            )
          }
          const obj = setObj({
            label: '',
            key: `${key}_requisite`,
            quantity: 1,
            coupon,
            product,
            variant,
            usePname: true,
            script_discount,
          })

          pre?.push(obj)
        }
      }
      const partSelect = copy_combineOption?.[`${key}_partSelect`]
      if (
        partSelect?.part_handlesku &&
        Number(partSelect?.value) > 0 &&
        partSelect?.part_handlesku !== 'quantity'
      ) {
        const [handle, sku] = partSelect?.part_handlesku?.split(',')
        const product = products?.find((v) => v?.handle === handle)
        const variant = product?.variants?.find((v) => v?.sku === sku)
        const coupon = getCoupons(variant?.sku, product?.handle)

        const part_label = getOptionLabel({
          key: `${key}_partSelect`,
          products,
          combineOption: copy_combineOption,
          combineoptions,
        })

        if (variant) {
          let script_discount = null
          if (combineoptions_commoninfo?.script_discount?.length) {
            script_discount = combineoptions_commoninfo?.script_discount?.find(
              (sd) =>
                sd?.related_handlesku === `${product?.handle},${variant?.sku}`
            )
          }
          const obj = setObj({
            label: part_label,
            key: `${key}_partSelect`,
            quantity: Number(partSelect?.value),
            coupon,
            product,
            variant,
            script_discount,
          })
          pre?.push(obj)
        }
      }

      return pre
    }, [])

    const tradeGift =
      contextTradeGift?.map((item, i) => {
        return setObj({
          label: '',
          key: `tradeGift-${i}`,
          ...item,
        })
      }) || []

    const last_combination = combination_v?.concat(tradeGift)
    return last_combination?.map((item) => {
      if (
        item?.script_discount &&
        last_combination?.some(
          (pp) =>
            item?.script_discount?.related_handlesku ===
            `${pp?.product?.handle},${pp?.variant?.sku}`
        )
      ) {
        const variant_price4wscode = item?.coupon?.variant_price4wscode
          ? Number(item?.coupon?.variant_price4wscode) -
            Number(item?.script_discount?.discount_value)
          : item?.script_discount?.discount_value
        return {
          ...item,
          coupon: {
            ...item?.coupon,
            variant_price4wscode,
          },
        }
      }
      return item
    })
  }
}

export const getCombineImg = ({
  key,
  handlesku,
  combineoptions,
  combineOption,
  products,
}) => {
  if (!handlesku) return ''

  let current_handlesku = handlesku

  const current = combineoptions?.[key]?.options?.find(
    (item) => item?.related_handlesku === handlesku
  )

  if (current?.special) {
    const hit = Object.entries(current?.special?.select)?.every(([k, v]) =>
      v?.includes(combineOption[k])
    )
    if (hit) {
      current_handlesku = current?.special?.handlesku
    }
  }

  if (combineOption?.[`${key}_partSelect`]) {
    const part_option = combineoptions?.[key]?.options?.find(
      (item) => item?.related_handlesku === current_handlesku
    )
    if (
      part_option?.partSelect?.type === 'quantity' &&
      Number(combineOption?.[`${key}_partSelect`]?.value) === 0
    )
      return ''
    const part_img = part_option?.partSelect?.options?.find(
      (item) =>
        String(item?.value) ===
        String(combineOption?.[`${key}_partSelect`]?.value)
    )?.imageUrl
    if (part_img) return part_img
    const [handle, sku] =
      combineOption?.[`${key}_partSelect`]?.related?.split(',')
    const product = products?.find((v) => v?.handle === handle)
    const variant = product?.variants?.find((v) => v?.sku === sku)
    if (product) return variant?.image?.url || product?.images?.[0]?.url
  }

  // const option_img = combineoptions?.[key]?.options?.find(
  //   (item) => item?.related_handlesku === current_handlesku
  // )?.imageUrl
  // if (option_img) return option_img

  const [handle, sku] = current_handlesku.split(',')
  const product = products?.find((v) => v?.handle === handle)
  const variant = product?.variants?.find((v) => v?.sku === sku)
  if (product) return variant?.image?.url || product?.images?.[0]?.url

  return ''
}

export const decimalAdjust = (type, value, exp) => {
  // If the exp is undefined or zero...
  if (typeof exp === 'undefined' || +exp === 0) {
    return Math[type](value)
  }
  value = +value
  exp = +exp
  // If the value is not a number or the exp is not an integer...
  if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
    return NaN
  }
  // Shift
  value = value.toString().split('e')
  value = Math[type](+(value[0] + 'e' + (value[1] ? +value[1] - exp : -exp)))
  // Shift back
  value = value.toString().split('e')
  return +(value[0] + 'e' + (value[1] ? +value[1] + exp : exp))
}

export const getDataFromFeature = ({ features, key }) => {
  const locales = Object.keys(features)
  return locales.reduce((acc, locale) => {
    acc[locale] = features[locale][key]
    return acc
  }, {})
}

import { I18N_STORE_DOMAIN } from '@shopify/const'

export const RegistrationsStatus = {
  MAIN: 'main',
  QUICK_LOGIN: 'quickLogin',
  REMAINDER: 'remainder',
  PASSWORD_LOGIN: 'passwordLogin',
  COUPONS: 'coupons',
  ACTIVATE: 'activate',
  SUBSCRIBE_SMS: 'subscribeSMS',
}

export const getAppName = (locale) => {
  const storeDomain = I18N_STORE_DOMAIN[locale || 'us']
  return storeDomain?.replace('.myshopify.com', '')
}

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

;globalThis["_sentryRewritesTunnelPath"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"cb4ac40e920ef5227baafa8a27db29c1cdbe4d6c"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import * as Sentry from '@sentry/nextjs'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

Sentry.init({
  allowUrls: [/https?:\/\/((beta|release|www)\.)?anker\.com/],
  environment: process.env.NEXT_PUBLIC_ENV,
  dsn:
    SENTRY_DSN ||
    'https://60868e49dfa6f214646d90cf7ed58f19@o487797.ingest.sentry.io/4504766856757248',
  // Adjust this value in production, or use tracesSampler for greater control
  integrations: [
    Sentry.browserTracingIntegration({
      enableInp: true,
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.005,
  debug: false,
  replaysOnErrorSampleRate: 0,
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  // replaysSessionSampleRate: 0.05,
  replaysSessionSampleRate: 0,

  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  // replaysOnErrorSampleRate: 1.0,

  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps

  beforeSend: (event, hint) => {
    if (event.tags?.errorScope) {
      if (
        event.exception?.values?.some((value) => {
          const lastFrame =
            value.stacktrace?.frames?.[
              (value.stacktrace?.frames?.length || 0) - 1
            ]

          return lastFrame?.filename?.includes('src/client/script')
        })
      ) {
        return null
      }

      return event
    }

    if (
      event.exception?.values?.some(
        ({ value }) =>
          value?.includes("Unexpected token ':'") ||
          value?.includes("unexpected token: ':'") ||
          value?.includes('Retry count of 5 exceeded') ||
          value?.includes('The operation is not supported') ||
          value?.includes('Blocked a frame with origin') ||
          value?.includes('load error:') ||
          value?.includes('Loading chunk') ||
          value?.includes('Load failed') ||
          value?.includes('The operation is insecure') ||
          value?.includes('Failed to load bugsnag') ||
          value?.includes('ADMITAD')
      )
    ) {
      return null
    }

    if (
      event.exception?.values?.some((value) => {
        const lastFrame =
          value.stacktrace?.frames?.[
            (value.stacktrace?.frames?.length || 0) - 1
          ]

        return (
          lastFrame?.in_app &&
          lastFrame.filename &&
          lastFrame.filename !== '<anonymous>' &&
          !lastFrame.filename.includes('node_modules') &&
          !lastFrame.filename.includes('src/client/script') &&
          value.stacktrace?.frames?.some(
            (frame) => frame.function === 'onClick'
          )
        )
      })
    ) {
      event.tags = {
        ...event.tags,
        errorScope: 'Client',
        errorType: 'RuntimeClickError',
      }
      return event
    }

    return null
  },
  beforeSendTransaction: (event) => {
    if (event.environment !== 'production') {
      return null
    }

    // 监控us的首页和listing页
    if (
      event.transaction === '/' ||
      event.transaction?.startsWith('/products') ||
      event.transaction?.startsWith('/[...paths]')
    ) {
      event.transaction = window.location.pathname
      return event
    }

    return null
  },
})

import { useEffect, useState, useCallback } from 'react'
import { useRouter } from 'next/router'
import { getAdCookie, appendParamsToUrl } from '@lib/utils/cookies'
const ORIGIN = process.env.NEXT_PUBLIC_ASSET_CDN || ''

export function useRelativeLink() {
  const { locale } = useRouter()
  const [refQuery, setRefQuery] = useState<string | undefined>('')

  useEffect(() => {
    const { ref_query } = getAdCookie()
    const search = window.location.search
    setRefQuery(ref_query || search)
  }, [locale])

  const setRelativeLink = useCallback(
    ({ link, params }: { link: string; params?: any }) => {
      if (!link || typeof link !== 'string' || link?.includes('http'))
        return link

      const regex = new RegExp(
        `(http:\/\/|https:\/\/|\/\/|mailto:|tel:|\/${locale})`,
        'g'
      )
      const baseUrl = regex?.test(link)
        ? `${ORIGIN}${link}`
        : `${ORIGIN}${locale === 'us' ? '' : '/' + locale}${link}`

      if (!ORIGIN) return appendParamsToUrl(baseUrl, params)

      try {
        if (
          link?.includes('ankersolix.com') ||
          (link?.includes('/anker-solix') && locale === 'fr')
        ) {
          return appendParamsToUrl(baseUrl, refQuery)
        }
      } catch (error) {
        console.log('error', baseUrl, link, error)
      }

      return appendParamsToUrl(baseUrl, params)
    },
    [locale, refQuery]
  )

  const setHtmlRelativeLink = useCallback(
    ({ html, extend = '' }: { html: string | undefined; extend?: string }) => {
      if (!html || typeof html !== 'string') return html

      const regex = new RegExp(
        `href=['"]((?!http:\/\/|https:\/\/|\/\/|mailto:|tel:)(?!\/${locale}\/)[^'"]+)['"]`,
        'g'
      )
      return html.replace(regex, (match, p1) => {
        const l = locale === 'us' ? '' : `/${locale}`
        const prefix = p1.startsWith('/') ? l : `${l}/`
        return `href="${prefix}${p1}${extend}"`
      })
    },
    [locale]
  )

  const setRefParameter = useCallback(
    ({ link, symbol = '' }: { link: string; symbol: string }) => {
      // 创建一个正则表达式来匹配ref参数
      const refRegex = /([?&]ref=)([^&]*)/

      // 检查URL是否包含ref参数
      if (refRegex.test(link)) {
        // 使用正则表达式替换ref参数的值
        return link.replace(refRegex, (match, p1, p2) => {
          return p1 + p2 + symbol
        })
      }

      // 如果URL中不包含ref参数，返回原始URL
      return link
    },
    []
  )

  return { setRelativeLink, setHtmlRelativeLink, setRefParameter }
}

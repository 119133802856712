import { FC } from 'react'
import cn from 'classnames'
import s from './LoadingDots.module.css'
interface LoadingProps {
  className?: string
}

const LoadingDots: FC<LoadingProps> = (props) => {
  const { className } = props
  return (
    <div className={cn(s.root, className)}>
      <b className={cn(s.dot, 'loading-dot')} key={`dot_1`} />
      <b className={cn(s.dot, 'loading-dot')} key={`dot_2`} />
      <b className={cn(s.dot, 'loading-dot')} key={`dot_3`} />
    </div>
  )
}

export default LoadingDots

import { FC, useEffect, useRef } from 'react'
import cn from 'classnames'
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
import s from './Sidebar.module.css'

const Sidebar = ({ children, onClose, show }) => {
  const sidebarRef = useRef()
  const contentRef = useRef()

  const onKeyDownSidebar = (event) => {
    if (event.code === 'Escape') {
      onClose()
    }
  }

  useEffect(() => {
    if (sidebarRef.current) {
      sidebarRef.current.focus()
    }

    const contentElement = contentRef.current

    if (contentElement && show) {
      disableBodyScroll(contentElement, { reserveScrollBarGap: true })
    }

    return () => {
      clearAllBodyScrollLocks()
    }
  }, [])

  return (
    <>
      <div
        className={cn(s.root, !show && 'hidden')}
        tabIndex="-1"
        ref={sidebarRef}
      >
        <div className="absolute inset-0 overflow-hidden">
          <a
            role="button"
            aria-label="close"
            className={s.backdrop}
            onClick={onClose}
          />
        </div>
      </div>
      <div
        onKeyDown={onKeyDownSidebar}
        className={cn(
          'fixed inset-y-0 right-0 z-[999999] flex max-w-full justify-end outline-none transition-all ease-in-out md:w-full md:translate-x-0 md:translate-y-0 md:flex-col',
          {
            'right-[-775px] md:right-0 md:translate-y-[120%] md:duration-300':
              !show,
          }
        )}
      >
        <div className="h-full w-full overflow-hidden md:h-[92vh] md:w-screen md:max-w-md">
          <div className={s.sidebar} ref={contentRef}>
            {children}
          </div>
        </div>
      </div>
    </>
  )
}

export default Sidebar

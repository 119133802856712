import '@assets/main.css'
import Cookies from 'js-cookie'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useRouter } from 'next/router'
import localFont from 'next/font/local'
// import type { AppProps } from 'next/app'
import dynamic from 'next/dynamic'
import { Text } from '@components/ui'
import { Head, Layout, Password } from '@components/common'
import { ManagedUIContext } from '@components/ui/context'
import { addCookie } from '@lib/utils/cookies'
import {
  useAllCollections,
  useFilterCollection,
} from 'lib/shopify/api/collection'
import { ProfileProvider } from '@components/common/ProfileContext'
import { useLivePreview } from '@payloadcms/live-preview-react'

import 'intersection-observer'

import '../components/common/CookieConsent/styles.css'
import { ErrorBoundary } from '@sentry/nextjs'

const ThirdParty = dynamic(() => import('@components/common/ThirdParty'))

const DINNextLT = localFont({
  variable: '--font-DINNextLTPro',
  src: [
    {
      path: '../fonts/DINNextLTPro-Light.otf',
      weight: '400',
      style: 'normal',
    },
    {
      path: '../fonts/DINNextLTPro-Regular.otf',
      weight: '500',
      style: 'normal',
    },
    {
      path: '../fonts/DINNextLTPro-Medium.otf',
      weight: '600',
      style: 'normal',
    },
    {
      path: '../fonts/DINNextLTPro-Bold.otf',
      weight: '700',
      style: 'normal',
    },
    {
      path: '../fonts/DINNextLTPro-Heavy.otf',
      weight: '800',
      style: 'normal',
    },
  ],
})

const FallbackComponent = () => {
  return (
    <div className="fit mx-auto flex max-w-2xl flex-col items-center justify-center px-10 py-20">
      <Text variant="heading">Oops, there is an error!</Text>
    </div>
  )
}

export default function MyApp({ Component, pageProps }) {
  const { locale } = useRouter()
  // const [password, setPassword] = useState(false)

  // const { data } = useAllCollections()

  // const { metafields = {}, slug } = pageProps

  // const {
  //   menuPps = {},
  //   menuCharger = {},
  //   pageType = {},
  //   copywriting = {},
  //   navSetting = {},
  //   shop_collections = {},
  // } = metafields

  // const navData =
  //   pageType?.pageType === 'powerStation' || pageType?.pageType === 'hes'
  //     ? menuPps
  //     : menuCharger

  // let dropDownContent = navData?.dropDownContent || []

  // const collectionList = useMemo(() => {
  //   const list = []
  //   if (
  //     (copywriting?.navCategoriesIncludePages == true ||
  //       copywriting?.navCategoriesIncludePages?.includes(slug)) &&
  //     !navSetting?.noneHeader
  //   ) {
  //     dropDownContent?.forEach((item) => {
  //       if (['collections', 'newCollections']?.includes(item?.mode)) {
  //         if (item?.mode === 'collections') {
  //           Array.isArray(item?.content) &&
  //             item?.content?.forEach((collection) => {
  //               if (collection?.handle) {
  //                 list.push(collection?.handle)
  //                 if (Array.isArray(shop_collections?.[collection?.handle])) {
  //                   list?.push(...shop_collections?.[collection?.handle])
  //                 }
  //               }
  //             })
  //         } else {
  //           Array.isArray(item?.content) &&
  //             item?.content?.forEach((collection) => {
  //               if (collection?.handle) {
  //                 list.push(collection?.handle)
  //                 if (Array.isArray(shop_collections?.[collection?.handle])) {
  //                   list?.push(...shop_collections?.[collection?.handle])
  //                 }
  //               }
  //               if (Array.isArray(collection?.collectionList)) {
  //                 collection?.collectionList?.forEach((subCollection) => {
  //                   if (subCollection?.handle) {
  //                     list.push(subCollection?.handle)
  //                     if (
  //                       Array.isArray(shop_collections?.[subCollection?.handle])
  //                     ) {
  //                       list?.push(...shop_collections?.[subCollection?.handle])
  //                     }
  //                   }
  //                 })
  //               }
  //             })
  //         }
  //       }
  //     })
  //   }

  //   return list
  // }, [dropDownContent])

  // const { data: results } = useFilterCollection(collectionList, {})

  const { data: payload } = useLivePreview({
    serverURL: process.env.NEXT_PUBLIC_PAYLOAD_URL || '',
    depth: 2,
    initialData: {
      ...pageProps?.payload,
      collection: pageProps?.type || 'pages',
    },
  })

  const PROPS = useMemo(
    () => ({
      ...pageProps,
      payload,
      locale,
      // categories: data || [],
      // navCategories: results || [],
    }),
    [locale, payload, pageProps]
  )

  // useEffect(() => {
  //   const testenv = Cookies.get('testenv')
  //   const authorization = Cookies.get('authorization_local')
  //   console.log('testenv', testenv)

  //   if (authorization === 'AnkerInner') {
  //     setPassword(false)
  //   } else {
  //     setPassword(testenv)
  //   }
  // }, [])

  useEffect(() => {
    const { shop } = pageProps
    addCookie({ locale, shopifyUrl: shop?.primaryDomain?.url })
    document.body.classList?.remove('loading')
  }, [locale, pageProps])

  return (
    <>
      <Head pageProps={PROPS} />
      <ManagedUIContext>
        <ErrorBoundary
          fallback={FallbackComponent}
          beforeCapture={(scope, error) => {
            scope.setTags({
              errorType: 'CrashError',
              errorScope: 'Client',
            })
            if (error) {
              error.name = `CrashError: ${location.origin + location.pathname}`
            }
          }}
        >
          <ProfileProvider>
            <Layout pageProps={PROPS}>
              <style jsx global>{`
                html {
                  font-family: ${DINNextLT.style.fontFamily}, -apple-system,
                    system-ui, BlinkMacSystemFont, 'Helvetica Neue', 'Helvetica',
                    sans-serif;
                }
              `}</style>
              <Component {...PROPS} />
              <ErrorBoundary
                beforeCapture={(scope, error) => {
                  scope.setTags({
                    errorType: 'ThirdPartyCrashError',
                    errorScope: 'Client',
                  })
                  if (error) {
                    error.name = `ThirdPartyCrashError: ${
                      location.origin + location.pathname
                    }`
                  }
                }}
              >
                <ThirdParty pageProps={PROPS} />
              </ErrorBoundary>
            </Layout>
          </ProfileProvider>
        </ErrorBoundary>
      </ManagedUIContext>
    </>
  )
}

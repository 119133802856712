import cn from 'classnames'
import React, { useEffect, useState, useMemo } from 'react'
import Icon from '@components/icons'
import dynamic from 'next/dynamic'
import { CommerceProvider } from '@shopify'
import { useAcceptCookies } from '@lib/hooks/useAcceptCookies'
import { useUI } from '@components/ui/context'
import { Sidebar, LoadingDots, Picture, Text } from '@components/ui'
import { CheckoutProvider } from '@components/checkout/context'
import { pageGTMEvent } from '@lib/utils/thirdparty'
import { refreshStarRating } from '@lib/utils/tools'
import Modal from 'react-modal'
import s from './Layout.module.css'
import { useRouter } from 'next/router'
import Button from '@components/control/Button'
import { Close } from '@components/icons/next'
import { checkPathName, keysSnakeToCamel } from '@components/helper'
import { I18N_STORE_DOMAIN, MULPASS_URL } from '@shopify/const'
import Crumbs from '@components/control/Crumbs'
import { ProfileProvider } from 'templates/Pages/Credit/ProfileContext'
const Loading = () => (
  <div className="flex h-80 w-full items-center justify-center p-3 text-center">
    <LoadingDots />
  </div>
)

const dynamicProps = {
  loading: Loading,
}

const NewCustomersPop = dynamic(() => import('../newCustomersPop'), {
  ...dynamicProps,
})

const AnkerDayPop = dynamic(() => import('../AnkerDayPop/AnkerDayPop'), {
  ...dynamicProps,
})

const ReferralPop = dynamic(() => import('../ReferralPop'), {
  ...dynamicProps,
})

const HesHeader = dynamic(() => import('@components/layout/Header'), {
  ...dynamicProps,
})
const ChgHeader = dynamic(() => import('@components/layout/ChgHeader'), {
  ...dynamicProps,
})

const Footer = dynamic(() => import('@components/layout/FooterNew'), {
  ...dynamicProps,
})

const HesFooter = dynamic(() => import('@components/layout/HesFooterNew'), {
  ...dynamicProps,
})

const Announcement = dynamic(() => import('@components/common/Announcement'), {
  ...dynamicProps,
})

const OnlineService = dynamic(
  () => import('@components/common/OnlineService'),
  {
    ...dynamicProps,
  }
)

const Subscribe = dynamic(() => import('@components/layout/Subscribe'), {
  ...dynamicProps,
})

const SignUpView = dynamic(() => import('@components/auth/SignUpView'), {
  ...dynamicProps,
})

const ForgotPassword = dynamic(
  () => import('@components/auth/ForgotPassword'),
  {
    ...dynamicProps,
  }
)

const FeatureBar = dynamic(() => import('@components/common/FeatureBar'), {
  ...dynamicProps,
})
const NewRelease = dynamic(() => import('@components/common/NewRelease'), {
  ...dynamicProps,
})

const ComponentModal = dynamic(() => import('@components/ui/Modal'), {
  ...dynamicProps,
  ssr: false,
})

const SoixStore = dynamic(
  () => import('templates/Pages/HomePps/component/SolixStore'),
  {
    ...dynamicProps,
  }
)
const LiveEntry = dynamic(
  () => import('templates/Pages/HomePps/component/LiveEntry'),
  {
    ...dynamicProps,
  }
)
const LocaleSwitcher = dynamic(() => import('../LocaleSwitcher'), {
  ...dynamicProps,
})
const PopSales = dynamic(() => import('@components/control/PopSales'), {
  ...dynamicProps,
})
const Registrations = dynamic(() => import('../Registrations'), {
  ...dynamicProps,
})
const RecallPop = dynamic(
  () => import('@components/common/RecallPop/RecallPop'),
  {
    ...dynamicProps,
  }
)
const ReferralIcon = dynamic(
  () => import('templates/Pages/Photovoltaic/ReferralIcon'),
  {
    ...dynamicProps,
  }
)
const NewReferralIcon = dynamic(() => import('../ReferralIcon'), {
  ...dynamicProps,
})
const CookieConsent = dynamic(() => import('../CookieConsent'), {
  ...dynamicProps,
})
const LoginPop = dynamic(() => import('../LoginPop/LoginPop'), {
  ...dynamicProps,
})
const TopBanner = dynamic(() => import('./TopBanner'), {
  ...dynamicProps,
})
const PaymentMethodView = dynamic(
  () => import('@components/checkout/PaymentMethodView'),
  {
    ...dynamicProps,
  }
)
const CheckoutSidebarView = dynamic(
  () => import('@components/checkout/CheckoutSidebarView'),
  {
    ...dynamicProps,
  }
)
const LoginView = dynamic(() => import('@components/auth/LoginView'), {
  ...dynamicProps,
})
const ShippingView = dynamic(
  () => import('@components/checkout/PaymentMethodView'),
  {
    ...dynamicProps,
  }
)
const CartSidebarView = dynamic(
  () => import('@components/cart/CartSidebarView'),
  {
    ...dynamicProps,
  }
)
const MenuSidebarView = dynamic(() => import('../UserNav/MenuSidebarView'), {
  ...dynamicProps,
})

const NewSoixStore = dynamic(
  () => import('templates/Pages/HomePps/component/Serve'),
  {
    ...dynamicProps,
  }
)

const SolixFanLayer = dynamic(
  () => import('templates/Pages/SolixFans/Components/LayerPop'),
  {
    ...dynamicProps,
  }
)

const CommonLayer = dynamic(() => import('components/common/CommonLayer'), {
  ...dynamicProps,
})

const ModalView = ({ modalView, closeModal, metafields }) => {
  return (
    <ComponentModal onClose={closeModal}>
      {modalView === 'LOGIN_VIEW' && <LoginView />}
      {modalView === 'SIGNUP_VIEW' && <SignUpView />}
      {modalView === 'FORGOT_VIEW' && <ForgotPassword />}
      {modalView === 'RECALL_VIEW' && <RecallPop metafields={metafields} />}
    </ComponentModal>
  )
}

const ModalUI = ({ metafields }) => {
  const { displayModal, closeModal, modalView } = useUI()
  return displayModal ? (
    <ModalView
      modalView={modalView}
      closeModal={closeModal}
      metafields={metafields}
    />
  ) : null
}

const SidebarView = ({
  sidebarView,
  closeSidebar,
  links,
  metafields,
  show,
  pageType,
}) => {
  // console.log('sidebarView:', sidebarView)
  return (
    <Sidebar onClose={closeSidebar} show={show}>
      <CartSidebarView metafields={metafields} pageType={pageType} />
    </Sidebar>
  )
}

const SidebarUI = ({ links, metafields, pageType }) => {
  const { displaySidebar, closeSidebar, sidebarView } = useUI()
  // console.log('displaySidebar:', displaySidebar)
  return (
    <SidebarView
      pageType={pageType}
      metafields={metafields}
      sidebarView={sidebarView}
      closeSidebar={closeSidebar}
      links={links}
      show={displaySidebar}
    />
  )
}

const Layout = ({
  children,
  pageProps: { navCategories = [], ...pageProps },
}) => {
  const { acceptedCookies, onAcceptCookies } = useAcceptCookies()

  const {
    metafields = {},
    pages,
    shop = {},
    locale,
    slug,
    handle,
    pageType,
    previewData,
    pageSetting,
    page,
    type,
  } = pageProps
  const {
    copywriting,
    preRender,
    pageCommon,
    shop_collections,
    shopTopBanner = {},
    shopTopBannerDup = {},
    referralIconPop = {},
    chargerSubScribe = {},
    chargerFooter = {},
    ppsFooter = {},
    ppsSubScribe = {},
    photovoltaicSubScribe = {},
    a1761SceneSubScribe,
    shopCommon = {},
    navSetting = {},
    shop_liveChat = {},
    orderLimits = {},
    menuPps,
    menuCharger,
    newCustomersPop = {},
    dealsNewCustomersPop = {},
    ankerDayPop = {},
    toReferralPop = {},
    cookieConsentSettings,
    disableBeforeConsent,
    necessaryCookies = [],
    advertisingCookies = [],
    analyticsCookies = [],
    performanceCookies = [],
    localeswitcher,
    registrationsSettings,
    registrationsModal,
    recallPop, //用户流失召回
    shoppingInfo,
    referralPop = {},
    globalCountry,
    googleIdentityPop,
  } = metafields

  shop.orderLimits = orderLimits
  shop.metafields = metafields

  const {
    tipModal,
    setTipModal,
    openSidebar,
    setPreviewData,
    setPageType,
    loginPop,
    setLoginPop,
    loginDefaultTab,
    showRegistrationsPopup,
    openModal,
    setModalView,
  } = useUI()

  const { asPath, query } = useRouter() //产品页 slug 为 handle
  const pathname = asPath?.split('?')?.[0]?.split('#')?.[0]

  const [popSales, setPopSalesStatus] = useState(false)

  const navStyle = () => {
    if (navSetting?.navMode === 'halfHeight') return ''
    if (navSetting?.navMode === 'transparent') return s.transparent
    if (navSetting?.navMode === 'fixed') {
      return !navSetting?.noneHeader && s.NavEmpty
    }
    if (navSetting?.navMode === 'none-fixed') {
      return !navSetting?.noneHeader && s.NavEmpty
    }
  }
  const subScribeData = () => {
    switch (pageType) {
      case 'powerStation':
        return a1761SceneSubScribe || ppsSubScribe
      case 'charger':
        return chargerSubScribe
      case 'hes':
        return photovoltaicSubScribe || ppsSubScribe
    }
  }
  const handleLiveChat = () => {
    pageGTMEvent({
      event: 'uaEvent',
      eventCategory: 'bottom_function',
      eventAction: 'customer_service',
      eventLabel: '',
    })
    pageGTMEvent({
      event: 'ga4Event',
      event_name: 'bottom_function',
      event_parameters: {
        action: 'customer_service',
      },
    })
  }

  useEffect(() => {
    setPageType(pageType)
  }, [pageType, setPageType])

  useEffect(() => {
    window.onload = () => {
      setTimeout(() => {
        refreshStarRating()
      }, 300)
    }

    // 登录弹窗 iframe 的监听
    window.addEventListener(
      'message',
      (event) => {
        if (
          event.origin === 'https://beta.passport.anker.com' ||
          event.origin === 'https://passport.anker.com' ||
          event.origin === 'http://localhost:8888'
        ) {
          const app = I18N_STORE_DOMAIN[locale].split('.').shift()
          // console.log('event:', event)
          if (event.data === 'loginSuccess') {
            setLoginPop(false)
            window.location.href = `${MULPASS_URL}/login?app=${app}&redirect=${window.location.href}`
          }
          if (event.data === 'registerSuccess') {
            if (window.fbq) {
              fbq('track', 'Complete registration')
            }
          }
          if (event.data === 'dialogClose') {
            setLoginPop(false)
          }
        }
      },
      false
    )

    // cart 全局文案
    if (localStorage) {
      localStorage.setItem(
        'customCartInfo',
        JSON.stringify(shoppingInfo?.customCartInfo)
      )
    }
  }, [])

  useEffect(() => {
    setPreviewData(pageProps?.previewData || {})
  }, [pageProps?.previewData])

  // recall modal
  useEffect(() => {
    const paramsRules = recallPop?.params
    if (
      Array.isArray(paramsRules) &&
      paramsRules.some((rule) => {
        const { label, val } = rule
        return query[label] === val
      })
    ) {
      const initEmail =
        query[paramsRules.find(({ _, val }) => val.includes('*'))?.label]
      setModalView('RECALL_VIEW')
      // setModalView('RECALL_VIEW', {
      //   ...recallPop,
      //   initEmail,
      // })
      openModal()
    }
  }, [recallPop, openModal, query, setModalView])

  const showOldSubscribe = useMemo(() => {
    if (pageType !== 'powerStation' && pageType !== 'hes') return true
    else {
      return !copywriting?.showHesNewFooter
    }
  }, [pageType, copywriting])

  return (
    <CommerceProvider
      locale={locale}
      shop={shop}
      moneyFormat={shop?.moneyFormat}
    >
      {previewData && previewData?.__preview_type && (
        <div className={s.previewDataTip}>
          <span>preview</span>
          <span className={s.previewDataTipDetail}>
            resource: {previewData?.__preview_type}
          </span>
          <span className={s.previewDataTipDetail}>
            namespace: {previewData?.__namespace}
          </span>
          <span className={s.previewDataTipDetail}>
            key: {previewData?.__key}
          </span>
        </div>
      )}
      <a name="top" id="top" />

      {localeswitcher?.show &&
        checkPathName({
          includes: localeswitcher.includePages,
          excludes: localeswitcher.excludePages,
          pathname,
        }) && <LocaleSwitcher settings={localeswitcher}></LocaleSwitcher>}

      {checkPathName({
        includes: newCustomersPop?.includePages,
        excludes: newCustomersPop?.excludePages,
        pathname,
      }) && (
        <NewCustomersPop
          pageType={pageType}
          data={{ ...keysSnakeToCamel(shopCommon), ...newCustomersPop }}
        />
      )}
      {checkPathName({
        includes: referralPop?.includePages,
        excludes: referralPop?.excludePages,
        pathname,
      }) && (
        <NewReferralIcon
          pageType={pageType}
          pageProps={pageProps}
          data={{ ...keysSnakeToCamel(shopCommon), ...referralPop }}
        />
      )}
      {checkPathName({
        includes: dealsNewCustomersPop?.includePages,
        excludes: dealsNewCustomersPop?.excludePages,
        pathname,
      }) && (
        <NewCustomersPop
          pageType={pageType}
          data={{ ...keysSnakeToCamel(shopCommon), ...dealsNewCustomersPop }}
        />
      )}
      {checkPathName({
        includes: ankerDayPop?.includePages,
        excludes: ankerDayPop?.excludePages,
        pathname,
      }) && (
        <AnkerDayPop
          data={{ ...keysSnakeToCamel(shopCommon), ...ankerDayPop }}
        />
      )}
      {checkPathName({
        includes: copywriting?.solixFan?.includePages,
        excludes: copywriting?.solixFan?.excludePages,
        pathname,
      }) &&
        (pageType == 'powerStation' || pageType == 'hes') && (
          <ProfileProvider>
            <SolixFanLayer data={copywriting?.solixFan} pathname={pathname} />
          </ProfileProvider>
        )}
      {checkPathName({
        includes: copywriting?.commonLayer?.includePages,
        excludes: copywriting?.commonLayer?.excludePages,
        pathname,
      }) &&
        (pageType == 'powerStation' || pageType == 'hes') && (
          <CommonLayer data={copywriting?.commonLayer} handle={handle} />
        )}
      <div className={cn(s.root, navStyle())}>
        {!navSetting?.noneHeader && (
          <>
            {/* 层级需高于头部的 999, 头部有 mask */}
            <div className="TopbannerBox relative z-[1000] l:z-0">
              {checkPathName({
                includes: shopTopBanner?.includePages,
                excludes: shopTopBanner?.excludePages,
                pathname,
              }) &&
                (shopTopBanner?.pageType
                  ? shopTopBanner.pageType === pageType
                  : true) &&
                shopTopBanner && (
                  <TopBanner
                    pageType={pageType}
                    key="shopTopBanner1"
                    shopCommon={shopCommon}
                    data={shopTopBanner}
                  />
                )}
            </div>
            <div className="TopbannerBox relative z-[1000] l:z-0">
              {/* 层级需高于头部的 999, 头部有 mask */}
              {checkPathName({
                includes: shopTopBannerDup?.includePages,
                excludes: shopTopBannerDup?.excludePages,
                pathname,
              }) &&
                (shopTopBannerDup?.pageType
                  ? shopTopBannerDup.pageType === pageType
                  : true) &&
                shopTopBannerDup && (
                  <TopBanner
                    pageType={pageType}
                    key="shopTopBannerDup1"
                    shopCommon={shopCommon}
                    data={shopTopBannerDup}
                  />
                )}
            </div>

            {slug === '/home' && !navSetting?.noneHeader && (
              <Announcement metafields={metafields} locale={locale} />
            )}

            <NewRelease locale={locale} slug={slug} metafields={metafields} />

            <div>
              {(shop_liveChat?.url || pageCommon?.whatupUrl) &&
                !pageCommon?.isLiveHelper && (
                  <div className={s.liveChat}>
                    <a
                      href={shop_liveChat?.url || pageCommon?.whatupUrl}
                      target={shop_liveChat?.target || '_blank'}
                      onClick={handleLiveChat}
                    >
                      <Icon
                        iconKey={shop_liveChat?.iconKey || 'liveChat'}
                        width={28}
                      />
                    </a>
                  </div>
                )}
            </div>
          </>
        )}
        {!navSetting?.noneHeader && (
          <>
            {pageType === 'powerStation' || pageType === 'hes' ? (
              <HesHeader
                navSetting={navSetting}
                pageType={pageType}
                categories={navCategories}
                shop_collections={shop_collections}
                shopCommon={shopCommon}
                liveContent={menuPps?.liveContent}
                lang={menuPps?.lang}
                dropDownContent={menuPps?.dropDownContent}
                dropDownSmallContent={menuPps?.dropDownSmallContent}
                metafields={metafields}
              />
            ) : (
              <ChgHeader
                navSetting={navSetting}
                pageType={pageType}
                categories={navCategories}
                shop_collections={shop_collections}
                shopCommon={shopCommon}
                liveContent={menuCharger?.liveContent}
                lang={menuCharger?.lang}
                dropDownContent={menuCharger?.dropDownContent}
                dropDownSmallContent={menuCharger?.dropDownSmallContent}
                dropDownRightContent={menuCharger?.dropDownRightContent}
                metafields={metafields}
              />
            )}
          </>
        )}
        {/* whatup app 悬浮链接，eu ca 站点全站有，如不想全局出现，可在页面的 page common 中配置 */}
        <main className="fit">{children}</main>
        <div>
          {pageCommon?.showCrumbs && (
            <Crumbs
              type={type}
              pageType={pageType}
              page={page}
              pageCommon={pageCommon}
            />
          )}
          {!navSetting?.noneFooter && (
            <>
              {(copywriting?.showNewSolixStoreHandles?.includes(slug) ||
                copywriting?.showNewSolixStoreAll) &&
                copywriting?.solixStoreNewInfo && (
                  <NewSoixStore
                    id="common_solixStore_new"
                    pageSetting={pageSetting}
                    data={copywriting?.solixStoreNewInfo}
                  ></NewSoixStore>
                )}
              {(copywriting?.showSolixStoreHandles?.includes(slug) ||
                copywriting?.showSolixStoreAll) &&
                copywriting?.solixStoreInfo && (
                  <SoixStore
                    id="common_solixStore"
                    pageSetting={pageSetting}
                    data={copywriting?.solixStoreInfo}
                  ></SoixStore>
                )}
              {(copywriting?.showLiveEntryHandles?.includes(slug) ||
                copywriting?.showLiveEntryAll) &&
                copywriting?.liveEntryInfo && (
                  <LiveEntry
                    id="common_liveEntry"
                    data={copywriting?.liveEntryInfo}
                  ></LiveEntry>
                )}
              {!preRender?.hideAllPageSubscribe &&
                !pageSetting?.hiddenFooterSubscribe &&
                !navSetting?.hiddenFooterSubscribe &&
                showOldSubscribe && (
                  <Subscribe
                    pageType={pageType}
                    metafields={metafields}
                    slug={slug}
                    data={subScribeData()}
                    locale={locale}
                  />
                )}
              {(pageType === 'powerStation' || pageType === 'hes') &&
              copywriting?.showHesNewFooter ? (
                <HesFooter
                  metafields={metafields}
                  pageType={pageType}
                  data={ppsFooter}
                  slug={slug}
                  locale={locale}
                  subScribeData={subScribeData()}
                />
              ) : (
                <Footer
                  metafields={metafields}
                  pageType={pageType}
                  data={chargerFooter}
                />
              )}
              <ModalUI metafields={metafields} />
              <CheckoutProvider>
                <SidebarUI metafields={metafields} pageType={pageType} />
              </CheckoutProvider>
            </>
          )}
        </div>
        {(pageCommon?.isLiveHelper || shopCommon?.isLiveHelper) && (
          <OnlineService metafields={metafields} />
        )}
        {/* Cookie Consent 弹窗 */}
        {cookieConsentSettings && (
          <CookieConsent
            cookieConsent={cookieConsentSettings}
            disableBeforeConsent={disableBeforeConsent}
            necessaryCookies={necessaryCookies}
            advertisingCookies={advertisingCookies}
            analyticsCookies={analyticsCookies}
            performanceCookies={performanceCookies}
          />
        )}
        {(!cookieConsentSettings ||
          cookieConsentSettings?.autorun === false) && (
          <FeatureBar
            title={
              copywriting?.acceptCookies ||
              'We use cookies on our website to give you the very best shopping experience we can. By using this site, you agree to its use of cookies.'
            }
            hide={acceptedCookies}
            action={
              <Button
                tag="Auto-1000070"
                small
                theme="brand"
                onClick={() => onAcceptCookies()}
                className="!h-[36px] !max-w-[172px] !text-[14px] !leading-[36px] l:mt-[20px] l:!max-w-[320px]"
              >
                {copywriting?.acceptCookiesBtn || 'Accept cookies'}
              </Button>
            }
          />
        )}
      </div>

      {checkPathName({
        includes: toReferralPop?.includePages,
        excludes: toReferralPop?.excludePages,
        pathname,
      }) && (
        <ReferralPop
          data={{ ...keysSnakeToCamel(shopCommon), ...toReferralPop }}
          pageType={pageType}
        />
      )}

      {checkPathName({
        includes: referralIconPop?.includePages,
        excludes: referralIconPop?.excludePages,
        pathname,
      }) && (
        <ReferralIcon
          data={{ ...keysSnakeToCamel(shopCommon), ...referralIconPop }}
          pageType={pageType}
        />
      )}
      {metafields?.popSales && (
        <PopSales
          metafields={metafields}
          lang={metafields?.popSales}
          open={popSales}
          setOpen={setPopSalesStatus}
        />
      )}

      {loginPop && <LoginPop defaultTab={loginDefaultTab} />}

      {orderLimits?.show && (
        <Modal
          isOpen={!!tipModal}
          onRequestClose={() => {
            setTipModal('')
            openSidebar()
          }}
          className={'Modal TipModal'}
          overlayClassName="Overlay"
          contentLabel="coupon-faq"
          ariaHideApp={false}
        >
          <Close
            onClick={() => {
              setTipModal('')
              openSidebar()
            }}
          />
          <Text html={tipModal} />
        </Modal>
      )}

      {registrationsSettings && (
        <Registrations
          pathname={pathname}
          registrations={registrationsSettings}
          showRegistrationsPopup={showRegistrationsPopup}
          registrationsModalMetafields={registrationsModal}
          path={asPath}
          pageType={pageType}
          globalCountry={globalCountry}
          showGoogleIdentity={googleIdentityPop?.show_googleIdentity}
        />
      )}
    </CommerceProvider>
  )
}

export default Layout
